
import React, { lazy, Suspense } from 'react'
import { Loader } from '@eig-builder/compositions-loader-with-timeout'

const CentredLoader = () => <Loader centeredY centeredX />

const LazyLoadWithCentredLoader = Comp => {
  const LazyComp = lazy(Comp)
  class LazyLoad extends React.Component {
    render () {
      return (
        <Suspense fallback={<CentredLoader />}>
          <LazyComp {...this.props} />
        </Suspense>
      )
    }
  }
  return LazyLoad
}

export default LazyLoadWithCentredLoader
