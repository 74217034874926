/* eslint-disable no-unused-expressions */
// babel Polyfill
import 'core-js/shim'
import 'regenerator-runtime/runtime'

// Add runtime config to singular runtime-config-helper
import { setRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'

setRuntimeConfig(window.runtimeConfig)

/* eslint-disable import/first */
import { init as initDatadog } from '@eig-builder/core-utils/helpers/apm/datadog-helper'

import { render } from 'react-dom'
import React from 'react'
import Root from 'app'

import {
  getQueryParametersFromUrl
} from '@eig-builder/core-utils/helpers/url-helper'
import get from 'lodash/get'

import './sass/main.scss'

// Set instanceJwt if its avaible in the url
const params = getQueryParametersFromUrl(window.location.href, true)
const ecomdashReturnUrl = get(params, 'returnurl')

if (ecomdashReturnUrl) {
  window.sessionStorage.setItem('ecomdash_return_url', ecomdashReturnUrl)
}

/* eslint-enable import/first */

if (!window.EventSource) {
  import(/* webpackChunkName: "event-source-polyfill" */ 'event-source-polyfill')
}

if (!window.Intl) {
  import(/* webpackChunkName: "intl" */ 'intl')
  import(/* webpackChunkName: "intl-en" */ 'intl/locale-data/jsonp/en.js')
}

initDatadog()

render(<Root />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept('app', () => {
    const NewRoot = require('app').default

    render(<NewRoot />, document.getElementById('root'))
  })
}
