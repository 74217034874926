import React, { lazy, Suspense } from 'react'
import CircularProgress from '@eig-builder/core-ui/CircularProgress'

const LazyLoadWithCircularProgress = Comp => {
  const LazyComp = lazy(Comp)

  class LazyLoad extends React.Component {
    render () {
      return (
        <Suspense fallback={<CircularProgress centered withPadding />}>
          <LazyComp {...this.props} />
        </Suspense>
      )
    }
  }
  return LazyLoad
}

export default LazyLoadWithCircularProgress
