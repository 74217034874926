import React from 'react'
import PropTypes from 'prop-types'

// Routes
import OnboardingRoutes from '../../../modules/onboarding/store-first/routes'

import VersionIndicator from '../../../core/version-indicator'

// Price container, to supply currency symbol to all Price components
import { PriceContainer } from '@eig-builder/module-price'

import Bootstrapper from '@eig-builder/module-bootstrapper'
import { PropertyEditorContext } from '@eig-builder/module-property-editor'
// Notification Bar
import { NotificationBarWrapperWithContext, NotificationBarContainer } from '@eig-builder/module-notification-bar-with-context'
import EventsContainer from '@eig-builder/module-application-events'

import Select from '@eig-builder/module-property-editor-field-select'
import MaskedInput from '@eig-builder/module-property-editor-field-maskedinput'
import Currency from '@eig-builder/module-property-editor-field-currency'
import Html from '@eig-builder/module-property-editor-field-html'

import { getLoginUrl, api } from '@eig-builder/core-utils/helpers/url-helper'

import * as GlobalStore from '@eig-builder/core-utils/store'
import LocalizationReducerFuncs from '@eig-builder/module-localization/store'
import ModalsReducerFuncs from '@eig-builder/module-modals/store'
import { sessionStorageMiddleware } from '../../../modules/onboarding/store-first/store/middleware'
import InjectGlance from '../../../core/injectGlanceForCtct'

import { ApolloProvider } from '@apollo/react-hooks'
import Autocomplete from '@eig-builder/module-property-editor-field-autocomplete'
import ApolloClient from 'apollo-boost'

const routes = [OnboardingRoutes]

const client = new ApolloClient({
  uri: `${api('/store-edit/v1.0/graphql').replace('st-edit', 'app-gateway')}`,
  credentials: 'include'
})

const BaseRouterWrapper = ({ children }) => (
  <PriceContainer>
    <EventsContainer>
      <ApolloProvider client={client}>
        <PropertyEditorContext.Provider value={{ useApiGateway: true, additionalFieldTypes: [Select, MaskedInput, Currency, Html, Autocomplete] }}>
          <NotificationBarWrapperWithContext>
            <NotificationBarContainer targetKey='fullscreen' />
            <InjectGlance app='APP_STORE_ONBOARDING'>
              {children}
            </InjectGlance>
            <VersionIndicator />
          </NotificationBarWrapperWithContext>
        </PropertyEditorContext.Provider>
      </ApolloProvider>
    </EventsContainer>
  </PriceContainer>
)

BaseRouterWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}
const LocalizationReducers = reducerObj => {
  reducerObj.localize = LocalizationReducerFuncs
}

const ModalsReducers = reducerObj => {
  reducerObj.modals = ModalsReducerFuncs
}
const localStore = GlobalStore.create({
  reduxLogger: !process.env.isProd
}, [LocalizationReducers, ModalsReducers], [sessionStorageMiddleware()])

export default () => {
  return (
    <Bootstrapper
      routes={routes}
      defaultRoute='/start'
      BaseRouterWrapper={BaseRouterWrapper}
      loginUrl={getLoginUrl()}
      store={localStore}
      AuthenticationContainerProps={{
        shouldLoadBillingInfo: true,
        shouldLoadIsEuCustomer: true,
        shouldLoadAccountInfo: true,
        shouldLoadTokenValidate: true
      }}
    />
  )
}
