import get from 'lodash/get'

import useFetchAsJson from '@eig-builder/core-utils/hooks/useFetchAsJson'
import { IncludeJavaScript } from '@eig-builder/core-utils/helpers/script-helper'
import { api } from '@eig-builder/core-utils/helpers/url-helper'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'

const appMap = {
  APP_STORE_EDITOR: 'APP_STORE_EDITOR',
  APP_STORE_ONBOARDING: 'APP_STORE_ONBOARDING'
}

const CTCT_PARTNER_ID = 50
const GLANCE_GROUP_ID = 25
const env = getRuntimeConfig()._env === 'prod' ? 'production' : 'staging'

// Appends the script or it doesn't - children are always rendered.
const InjectGlanceForCtct = (props) => {
  let ctctId

  if (appMap[props.app] === 'APP_STORE_EDITOR') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [accountInfo] = useFetchAsJson({
      url: api('/v1.0/store/setup/status', 'store-edit')
    })
    if (accountInfo.isSuccess) {
      ctctId = get(accountInfo, 'data.ctct_external_id')
    }
    if (accountInfo.isError) {
      console.error('Did not set glance script due to api error')
    }
  }
  if (appMap[props.app] === 'APP_STORE_ONBOARDING') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [accountInfo] = useFetchAsJson({
      url: api('/v1.0/account/info', 'accounts'),
      credentials: 'same-origin'
    })
    if (accountInfo.isSuccess) {
      const isCTCT = get(accountInfo, 'data.partner_id') === CTCT_PARTNER_ID
      if (isCTCT) {
        const externalIds = get(accountInfo, 'data.external_ids')
        externalIds.forEach(object => {
          if (object.type && object.type === 'EmailMarketing') {
            ctctId = object.id
          }
        })
      }
    }
    if (accountInfo.isError) {
      console.error('Did not set glance script due to api error')
    }
  }
  if (ctctId) {
    window.GLANCE_COBROWSE = { presenceserver: 'presence.glance.net', visitorid: `${ctctId}` }
    const url = `https://constantcontact.glancecdn.net/cobrowse/CobrowseJS.ashx?group=${GLANCE_GROUP_ID}&site=${env}`

    IncludeJavaScript(url, 'cobrowsescript', undefined, undefined, {
      id: 'cobrowsescript',
      'data-groupid': GLANCE_GROUP_ID,
      'data-site': env,
      skin: 'Custom',
      charset: 'UTF-8',
      'data-presence': 'on'
    })
  }
  return props.children
}
export default InjectGlanceForCtct
