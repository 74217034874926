import { LazyLoadWithCircularProgress } from '../../../core/lazyLoad'

import { CREATION } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

export const PRODUCT_ONBOARDING_BASE_PATH = '/onboarding/part1'

const Routes = [
  {
    path: '/start',
    pageTitle: 'StoreFirst Onboarding',
    component: LazyLoadWithCircularProgress(() => import('./components/start')),
    isPublic: true,
    pathToLeaveFocusView: router => {
      window.history.back()
    },
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: '/shoppable',
    pageTitle: 'StoreFirst Onboarding',
    component: LazyLoadWithCircularProgress(() => import('./components/start')),
    isPublic: true,
    pathToLeaveFocusView: router => {
      window.history.back()
    },
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: '/ecomdash/auth',
    pageTitle: 'Ecomdash',
    component: LazyLoadWithCircularProgress(() => import('./components/ecomdash-connect-sales-channel-pre-authenticate')),
    isPublic: true,
    pathToLeaveFocusView: router => {
      window.history.back()
    },
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: '/ecomdash',
    pageTitle: 'Ecomdash',
    component: LazyLoadWithCircularProgress(() => import('./components/ecomdash-connect-sales-channel-start')),
    isPublic: true,
    pathToLeaveFocusView: router => {
      window.history.back()
    },
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: PRODUCT_ONBOARDING_BASE_PATH,
    pageTitle: 'StoreFirst Onboarding',
    component: LazyLoadWithCircularProgress(() => import('./containers/product-onboarding')),
    isPublic: true,
    pathToLeaveFocusView: router => {
      window.history.back()
    },
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: '/onboarding/part2/1',
    pageTitle: 'StoreFirst Onboarding',
    component: LazyLoadWithCircularProgress(() => import('../../onboarding2/pages/flow-2')),
    isPublic: true,
    pathToLeaveFocusView: router => {
      window.history.back()
    },
    pageType: CREATION,
    flowType: STORE
  }
]

export default Routes
