import pick from 'lodash/pick'

export const sessionStorageMiddleware = () => store => next => action => {
  const { doLocalStorage, LocalStorageKeyName, storeKeyState, fields } = action
  next(action)

  if (doLocalStorage && fields) {
    const stateData = store.getState()[storeKeyState]
    const newState = pick(stateData, fields)

    const encodedData = newState && encodeURIComponent(JSON.stringify(newState))
    encodedData && window.sessionStorage.setItem(LocalStorageKeyName, encodedData)
  }
}
