import React from 'react'
import styled from 'styled-components'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import { getCookie } from '@eig-builder/core-utils/helpers/cookie-helper'
import { api } from '@eig-builder/core-utils/helpers/url-helper'
import fetch from '@eig-builder/core-utils/fetch'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import toString from 'lodash/toString'
import keys from 'lodash/keys'

const VersionIndicatorWrapper = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  background-color: #fff;
  padding: 3px 10px;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
`

const Item = styled.div`
  border-right: 1px solid #a2a2a259;
  padding: 0 10px!important;
`

const CloseIcon = styled.div`
  position: absolute;
  right: 8px;
  top: 20px;
  font-size: 17px;
`

const ItemTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
`

const ItemContent = styled.div`
  font-size: 15px;
`

const ItemDeployment = styled.div`
  font-size: 10px;
`

class VersionIndicatorComponent extends React.Component {
  state = {
    visible: true,
    dependencies: []
  }

  async componentDidMount () {
    if (getRuntimeConfig()._env === 'prod') {
      return null
    }

    const backendInfoObjs = []
    // read cookie
    const backendEnvToResolve = {
      'store-editor-api': 'st-edit-api',
      'store-shop-api': 'st-shop-api'
    }

    const cookie = getCookie('builderEnvV2')
    let featureFlags = {}
    let urlsForBrand = {}

    try {
      const cookieOjb = JSON.parse(cookie)
      urlsForBrand = get(cookieOjb, getRuntimeConfig()._brand)
      featureFlags = get(cookieOjb, 'featureFlags')
    } catch (ex) {

    }

    for (const key in backendEnvToResolve) {
      let backendEnv = getRuntimeConfig()._env
      const subDomain = backendEnvToResolve[key]

      if (indexOf(keys(urlsForBrand), subDomain) !== -1) {
        const feature = find(featureFlags, x => {
          return toString(urlsForBrand[subDomain]).indexOf(x) !== -1
        })
        if (feature) {
          backendEnv = feature
        }
      }

      const backendInfoObj = {
        key: key,
        name: subDomain,
        env: backendEnv,
        buildFlag: null,
        buildTime: null
      }
      backendInfoObjs.push(backendInfoObj)
      this.getVersionFromBackend(key)
    }

    this.setState({
      dependencies: backendInfoObjs
    })
  }

  async getVersionFromBackend (key) {
    const backendEnvToResolve = {
      'store-editor-api': 'store-edit',
      'store-shop-api': 'store-shop'
    }
    const response = await fetch(api('/system/status', backendEnvToResolve[key]), {
      credentials: 'include'
    })
    const body = await response.json()

    const newDepts = cloneDeep(this.state.dependencies)
    const dept = find(newDepts, dept => dept.key === key)
    dept.buildFlag = get(body, 'information.version.build')
    dept.buildTime = get(body, 'information.version.version')
    this.setState({
      dependencies: newDepts
    })
  }

  handleHideBar = () => {
    this.setState({ visible: false })
  }

  render () {
    const env = getRuntimeConfig()._env

    if (getRuntimeConfig()._env === 'prod') {
      return null
    }

    if (!this.state.visible) {
      return null
    }

    let frontendEnv = env
    if (getRuntimeConfig()._ticketLabel) {
      frontendEnv = getRuntimeConfig()._ticketLabel
    }
    return (
      <VersionIndicatorWrapper>
        <div className='row'>
          <Item className='col-auto'>
            <ItemTitle>frontend</ItemTitle>
            <ItemContent>{frontendEnv}</ItemContent>
            <ItemDeployment>#{getRuntimeConfig()._buildFlag} - {getRuntimeConfig()._buildTime}</ItemDeployment>
          </Item>
          {this.state.dependencies.map((dependency, index) =>
          (
            <Item className='col-auto' key={index}>
              <ItemTitle>{dependency.name}</ItemTitle>
              <ItemContent>{dependency.env}</ItemContent>
              <ItemDeployment>#{dependency.buildFlag} - {dependency.buildTime}</ItemDeployment>
            </Item>
        )
        )}
        </div>
        <CloseIcon className='col-auto' onClick={this.handleHideBar}>
          X
        </CloseIcon>
      </VersionIndicatorWrapper>
    )
  }
}

export default VersionIndicatorComponent
